<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
  >
    <template v-slot:activator="{ on, attrs }">
      <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
        <IconDotsH width="18"/>
      </UiBtn>
    </template>
    <v-list nav dense color="gray-10">
      <v-list-item
        :disabled="false"
        @click="downloadSummary">
        <IconDownload width="18" class="mr-2 gray-60--text" />
        <v-list-item-title class="text-captions-1">Download Summary</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!module.is_multiple && module.item?.file"
        :disabled="fileLoading"
        :loading="fileLoading"
        @click="download(module.item.file)"
      >
        <v-list-item-title class="text-captions-1">
          <IconDownload width="14" class="mr-2 gray-60--text"/>
          Download Asset
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :disabled="!showEditAccessButton || loading"
        @click="openEditAccessModal">
        <IconShare width="18" class="mr-2 gray-60--text" />
        <v-list-item-title class="text-captions-1">Share</v-list-item-title>
      </v-list-item>
      <UIFilesUploader :files-formats="allowedExtensionTypeForModule"
                       :max-file-size="$config.filesystem.project.design.file.maxUploadFileSize" multiple
                       v-if="!module?.is_multiple && module?.item?.gallery_group_id"
                       :disabled="!abilityToUpload" @onChange="createDesign($event, true)" class="d-flex">
        <v-list-item ripple class="cursor-pointer mb-1" :disabled="!abilityToUpload">
          <v-list-item-title class="text-caption-1">
            <IconAddNewItem width="14" class="mr-2 gray-60--text"/>
            Upload Fix/New Version
          </v-list-item-title>
        </v-list-item>
      </UIFilesUploader>
      <v-list-item
        v-if="module.is_viewed"
        :disabled="false"
        @click="toggleModulesView(true)">
        <IconEyeCrossed width="18" class="mr-2 gray-60--text" />
        <v-list-item-title class="text-captions-1">Mark as Unseen</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!module.is_viewed"
        :disabled="false"
        @click="toggleModulesView(false)">
        <IconEyeOutlined width="18" class="mr-2 gray-60--text" />
        <v-list-item-title class="text-captions-1">Mark as Seen</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="module.item?.status === $config.project.status.in_review"
        :disabled="checkIfDisabled($config.project.status.rejected) || !abilityToReview || !module.item?.file"
        @click="approveOrReject($config.project.status.rejected)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCancel width="14" class="mr-2 gray-60--text"/>
          Request adjustments
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="module.item?.status === $config.project.status.in_review"
        :disabled="checkIfDisabled($config.project.status.approved) || !abilityToReview || !module.item?.file"
        @click="approveOrReject($config.project.status.approved)"
      >
        <v-list-item-title class="text-captions-1">
          <IconCheck width="14" class="mr-2 gray-60--text"/>
          Approve design
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import projectCompleteMixin from "@/mixins/projectCompleteMixin"
import UIFilesUploader from "@/components/UI/UIFilesUploader.vue";
import {getFileExtension} from "@/utils/helpers";

export default {
  name: 'GlobalDashboardTableMenu',
  components: {
    UIFilesUploader,
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconAddNewItem: () => import('@/components/icons/IconAddNewItem'),
    IconShare: () => import("@/components/icons/IconShare"),
    IconEyeCrossed: () => import("@/components/icons/IconEyeCrossed"),
    IconEyeOutlined: () => import("@/components/icons/IconEyeOutlined"),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconCheck: () => import('@/components/icons/IconCheck'),
    UiMenu,
    UiBtn
  },
  mixins: [projectCompleteMixin],
  props: {
    project: {
      type: Object,
      default: null,
    },
    module: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      fileLoading: false
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
      'getProjectPermissionData',
      'getAuthId',
    ]),
    allowedExtensionTypeForModule() {
      if(this.module?.slug === this.$config.project.modules.cadDesign && this.module.item.file) {
        const idx = this.$config.project.modulesFilesFormatGroup['cad-design'].findIndex(types => types.includes(getFileExtension(this.module.item?.file?.original_name)))
        return this.$config.project.modulesFilesFormatGroup['cad-design'][idx]
      } else  {
        return this.$config.project.modulesFileFormats[this.module?.slug]
      }
    },
    showEditAccessButton() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (this.project?.user?.id === this.getAuthId ||
        this.getProjectPermissionData(this.project.id).roles.some(role => role === projectOwner || role === projectManager)
      )
    },
    abilityToUpload() {
      const project_id = this.project.id;
      const activeModule = this.module;
      return this.getPermission(project_id).design['can-upload-file-design'] &&
        this.getProjectPermissionData(project_id).availableModules.includes(activeModule.slug);
    },
    abilityToReview() {
      if (!this.module?.item) return false;
      // const activeFile = this.file || this.getActiveFile;
      return this.module.item?.request_reviews?.find(user => user.user_id === this.getAuthId) || this.isManagerOrOwner;
    },
    isManagerOrOwner() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return this.getProjectPermissionData(this.project.id).roles.some(role => role === projectOwner || role === projectManager);
    }
  },
  methods: {
    async downloadSummary() {
      try {
        const {data} = await this.$api.project.downloadSummary(this.project.id, this.module.id)
        if(data?.url) {
          window.open(data.url, 'Download');
        }
      } catch (error) {
        console.error(error)
      }
    },
    async createDesign(files, newVersion = true) {

      this.fileLoading = true;
      this.$emit('setGlobalLoading', true);
      let formData = new FormData();
      if (this.module.slug === this.$config.project.modules.cadDesign && !newVersion) {
        const cadAllowedType = [...this.$config.project.modulesFilesFormatGroup['cad-design'][1]]
        const cadFiles = []

        files.forEach(file => {

          if (cadAllowedType.includes(getFileExtension(file.name))) {
            cadFiles.push(file)
          }
        })

        Object.values(this.module.item.style.grouped_items).forEach(([file]) => {
          if (cadAllowedType.includes(getFileExtension(file.file.original_name))) {
            cadFiles.push(file)
          }
        })

        // if (cadFiles.length > 1) {
        //   await this.$store.dispatch('openModal', {
        //     modalName: 'fileUploadErrorModal',
        //     data: {
        //       type: 'limitError',
        //     }
        //   });
        //   this.fileLoading = false;
        //   return
        // }
      }


      files.forEach((file, idx) => {
        formData.append(`files[${idx}][file]`, file);
        formData.append(`files[${idx}][style_id]`, this.module.item.style.id);

        this.project.tag.forEach(tag => {
          formData.append(`files[${idx}][tags][]`, tag.id);
        });
      });

      if (newVersion && this.module.item.gallery_group_id) {
        formData.append('gallery_group_id', this.module.item.gallery_group_id)
      }

      try {
        await this.$api.projectModuleDesign.create(this.project.id, this.module.id, formData);
        this.$emit('updateData')
        this.$eventBus.$emit('updateQuickFilterStatuses');
        // const newFiles = res.data[res.data.length - 1].items.map(item => ({
        //   ...item,
        //   request_reviews: []
        // })).filter(file => file.project_module.id === +this.$route.query.module_id)
        // const newFile = newFiles[0];
        // this.$store.dispatch('setActiveStyle', {
        //   ...res.data[res.data.length - 1],
        //   items: newFiles
        // });

        // this.$store.dispatch('setActiveFile', newFile);

        // this.$router.$updateQueryParams({file_id: newFile.id});

        // if (!newVersion || !this.$route.query.gallery_group_id) {
        //   this.$router.$updateQueryParams({gallery_group_id: newFile.gallery_group_id});
        // }
      } catch (error) {
        console.error(error);
      } finally {
        this.fileLoading = false;
        this.$emit('setGlobalLoading', false);
        // we need to load the project to check if the module has files
        // if module don't have any files, it will be available to remove
        // const project = await this.$api.project.get(this.getProject.id);
        // this.$store.dispatch('setProject', project.data);
        // this.$store.dispatch('setDesignModules', project.data.modules);
      }
    },
    async openEditAccessModal() {
      await this.$store.commit('SET_PROJECT', this.project);
      this.$store.dispatch('openModal', {
        modalName: 'editModuleAccessModal',
        data: {
          module_id: this.module.id,
          module: this.module,
          project: this.project
        },
      });
    },
    async toggleModulesView(unview) {
      const payload = {
        ids: [this.module.id],
        unview: unview,
      }
      try {
        await this.$api.dashboard.toggleModulesView(payload);
        this.$emit('updateData')
      } catch (err) {
        console.error(err)
      }
    },
    async download(file) {
      if (this.fileLoading || !file) return;
      this.fileLoading = true;
      const image = await fetch(file.download_url || file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.download_file_name || file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
    checkIfDisabled(status) {
      if (!this.module.item) return true;
      // const activeFile = this.file || this.getActiveFile;
      return this.module?.item?.status === status;
    },
    approveOrReject(status) {
      this.$store.dispatch('openModal', {
        modalName: 'approveRejectModal',
        data: {
          status,
          files: [this.module.item],
          project: this.project
        },
        handlers: {
          onUpdate: () => {
            this.$emit('updateData')
          }
        }
      });
    },
  },
}
</script>
